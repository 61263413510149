import React, { useEffect, useState, useCallback } from "react";
import DropdownSelect from "./DropdownSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faLockOpen,
  faLock,
  faWandMagicSparkles,
  faSquare,
  faPencil,
  faEdit,
  faTrash,
  faSun,
  faExclamationCircle,
  faCheck,
  faSpinner,
  faTimes
} from "@fortawesome/free-solid-svg-icons";
import DiagnosisChip from "./DiagnosisChip/DiagnosisChip";
import { createImageLabel } from "../store/actions/label";
import { useDispatch, useSelector } from "react-redux";
import { getNextImage } from "../store/actions/images/next";
import { getImageCount } from "../store/actions/count";
import { Spinner } from "react-bootstrap";
import {
  updateLabelsImage,
  removeLabelsImage,
} from "../store/actions/images/previous";
import { updateCurrentImage } from "../store/reducers/images/imagesSlicer";
import {
  clearPathologiesList,
  addNewPathologies,
  clearFreehandPathologiesList,
  clearReactanglesFromFreehandList
} from "../store/actions/pathologies";
import { clearDiagnosesList } from "../store/actions/diagnoses";
import { clearTagsList } from "../store/actions/tags";
import { removePathology } from "../store/actions/pathologies";
import { getColorForValue } from "../helpers/getColors";
import { updateDiagnoses } from "../store/reducers/diagnoses/diagnosesSlicer";
import { updateTags } from "../store/reducers/tags/tagsSlicer";
import { addNewTags, removeTag } from "../store/actions/tags";
import { Button } from "react-bootstrap";
import { getSelectorOptionsThunk } from "../store/actions/selectorOptions";
import { rejectService } from "../services";
import ViewerPolling from "./ViewerPolling";
import Pathologies from "./Pathologies";
import { aiLabelService } from './../services/ai.service';
import { handleSliderlock, handleSliderToggles } from '../store/actions/slider';
import socketio from './../helpers/socket-client';
import { addFreehandPathology, updateFreehandDrawingsList } from "../store/actions/pathologies";
import { openWindow } from '../store/actions/window';
import FilmStrip from "./FilmStrip";
import RCSlider from "rc-slider";
import "rc-slider/assets/index.css";
import { pathologyOtherOptions } from "../constants";

const Slider = (props) => {
  const [selectedPathologyOption, setSelectedPathologyOption] = useState({
    value: "",
    label: "Select pathology",
  });

  const diagnosisOptions = useSelector(
    (state) => state.selectionOptions.options.diagnosis
  );
  const previousImages = useSelector(
    (state) => state.previous.previous
  );

  const [localDiagnosisOptions, setLocalDiagnosisOptions] = useState(diagnosisOptions);

  const pathologyOptions = useSelector(
    (state) => state.selectionOptions.options.pathologies
  );

  const [localPathologyOptions, setLocalPathologyOptions] = useState(pathologyOptions);

  const [showConfigPrompt, setShowConfigPrompt] = useState(false);

  const tagOptions = useSelector(
    (state) => state.selectionOptions.options.tags
  );

  const [localTagOptions, setLocalTagOptions] = useState(tagOptions);

  const [selectedPathologyOtherOption, setSelectedPathologyOtherOption] =
    useState({
      value: "",
      label: "Other",
    });

  const [selectedDiagnosisOption, setSelectedDiagnosisOption] = useState({
    value: "",
    label: "Select diagnosis",
  });

  const [displayedDiagnosisOption, setDisplayedDiagnosisOption] = useState({
    value: "",
    label: "Select diagnosis",
  });

  const [otherDiagnosisOption, setOtherDiagnosisOption] = useState({
    value: "",
    label: "Other",
  });

  const [isOtherDiagnosisSubmitted, setIsOtherDiagnosisSubmitted] =
    useState(false);

  const currentImage = useSelector((state) => state.next.next);

  const selectedImage = useSelector((state) => state.images?.currentImage);

  const projectId = useSelector((state) => state.projects.selectedProject);
  const projects = useSelector((state) => state.projects.projects);

  const [isRemoving, setIsRemoving] = useState(false);

  const rectangleDrawings = useSelector(
    (state) => state.pathologies.pathologies
  );

  const freehandDrawings = useSelector(
    (state) => state.pathologies.freehandDrawings
  );

  const freehandPoints = useSelector(
    (state) => state.pathologies.freehandDrawings?.points
  );

  const selectedRectanglesCoords = useSelector(
    (state) => state.pathologies.updateList
  );

  const rectanglesFromFreehand = useSelector(
    (state) => state.pathologies.rectanglesFromFreehand
  );

  const selectedDiagnoses = useSelector((state) => state.diagnoses.diagnoses);

  const [error, setError] = useState("");

  const tags = useSelector((state) => state.tags.tags);
  const [loading, setLoading] = useState(false);
  const [loadingNext, setLoadingNext] = useState(false);
  const [loadingStates, setLoadingStates] = useState({});
  const [editablePathologyIndex, setEditablePathologyIndex] = useState(null);

  const [showPrompt, setShowPrompt] = useState(false);
  const [showZeroCountModal, setShowZeroCountModal] = useState(false);
  const [imageSetExists, setImageSetExists] = useState(false);
  const [imageSet, setImageSet] = useState(null);
  const [otherImages, setOtherImages] = useState([]);
  const [imageCount, setImageCount] = useState(0);

  const [restoreReason, setRestoreReason] = useState(""); // State for restore reason
const [showRestoreInput, setShowRestoreInput] = useState(false); // State to toggle input visibility
  const [currentDatasetIndex, setCurrentDatasetIndex] = useState(0);

  const nextImage = useSelector((state) => state.next.next);

  const dispatch = useDispatch();

  const projectAtLoginData = useSelector(
    (state) => state.projects.projectSelectedAtLogin
  );

  const drawingFlagCheck = useSelector(
    (state) => state.selectionOptions.options.freehand_enabled
  );

  const rejectOptions = [
    { value: "Poor Quality", label: "Poor Quality" },
    { value: "Not referenced image", label: "Not referenced image" },
    { value: "Require Second Opinion", label: "Require Second Opinion" },
    { value: "Other", label: "Other" },
  ];

  const unrejectOptions = [
    { value: "Poor Quality", label: "Poor Quality" },
    { value: "Not referenced image", label: "Not referenced image" },
    { value: "Require Second Opinion", label: "Require Second Opinion" },
    { value: "Other", label: "Other" },
  ];

  const [selectedRejectOption, setSelectedRejectOption] = useState({value: "", label: "Reject"});

  const [unselectedRejectOption, setunSelectedRejectOption] = useState({value: "", label: "Unreject"});

  // Handle canceling the restore action
  const handleCancelRestore = () => {
    setShowRestoreInput(false);
    setRestoreReason(""); // Clear the restore reason
  };

  // const [restoreReason, setRestoreReason] = useState("");

  const [prevProjectId, setPrevProjectId] = useState(projectId);

  const [selectedPathologies, setSelectedPathologies] = useState([]);

  const [startPolling, setStartPolling] = useState(false);
  const [clickTimestamp, setClickTimestamp] = useState(null);

  const { open, locked } = useSelector(state => state.slider);
  const [isSegmentationLoaded, setIsSegmentationLoaded] = useState(false);

  const image = otherImages[0]; // Select the first image or any single image from the array
  const imageId = image?._id;

  const pathCount = useSelector((state) => state.pathologiesCount.displayPathologiesCount);
  const { openWindow } = useSelector(state => state.window);

  const getSelectedProject = (state) => {
    return state.projects.projects.find(project => project._id === state.projects.selectedProject);
  }

  const isReject = () => {
    const islocalNext = previousImages.filter(p=> p.pID === projectId)
    const isNext = islocalNext.findIndex(i=> i.image._id === selectedImage._id)
    // console.log('is reject 2', islocalNext[isNext]?.pathologies?.rejection )
    // setShowRestoreInput(false)
    return islocalNext[isNext]?.pathologies?.rejection
  }
  
  const [imageRejected, setImageRejected] = useState(isReject());

  const project = useSelector(getSelectedProject);

  // useEffect(() =>(
  //   // console.log('version', project.version)
  // ), [project]);

  // Effect to reset the segmentation loaded state when the imageId changes
  useEffect(() => {
    setIsSegmentationLoaded(false);
  }, [imageId]);

  useEffect(() => {
    // console.log('open', open)
    // console.log('locked', locked)
  }, [open, locked])

  // useEffect(() => {
  //   setSelectedPathologies(freehandDrawings);
  // }, [freehandDrawings]);

  useEffect(() => {
    console.log("selectedPathologies1", freehandDrawings);
    console.log("selectedPathologies2", rectangleDrawings);
    console.log("selectedPathologies3", drawingFlagCheck);
    const _selectedPathologies = drawingFlagCheck ||  project.version ===2 ? freehandDrawings : rectangleDrawings;
    setSelectedPathologies(_selectedPathologies);
  }, [drawingFlagCheck, freehandDrawings, rectangleDrawings]);

  useEffect(() => {
    console.log("selectedPathologies", selectedPathologies);
  }, [selectedPathologies]);

  const handleChange = (e) => {
    props.onToggle(e.target.checked);
  };
  
  const handleLaunchViewer = (timestamp) => {
    setStartPolling(true);
    setClickTimestamp(timestamp)
  };

  const handleRejectOptionChange = (selected) => {
    const imageId = selectedImage ? selectedImage._id : currentImage ? currentImage._id : otherImages.length > 0 ? otherImages[0]._id : null;
  
    if (imageId) {
      rejectService.reject(projectId, [imageId], selected.value, 3) 
        .then(() => {
          handleNextImage(true);
          // console.log('Image rejection successful');
        })
        .catch((error) => {
          console.error('Failed to reject image:', error);
        });
    } else {
      // console.log('No images to reject');
    }
  };  

  // const handleUnrejectOptionChange = () => {
  //   const imageId = selectedImage
  //     ? selectedImage._id
  //     : currentImage
  //     ? currentImage._id
  //     : otherImages.length > 0
  //     ? otherImages[0]._id
  //     : null;
  
  //   if (imageId) {
  //     rejectService
  //       .unreject(projectId, [imageId], restoreReason) // Assuming the backend has an "unreject" endpoint
  //       .then(() => {
  //         setImageRejected(false); // Move to the next image if necessary
  //         console.log('Image unrejection successful');
  //       })
  //       .catch((error) => {
  //         console.error('Failed to unreject image:', error);
  //       });
  //   } else {
  //     console.log('No images to unreject');
  //   }
  // };

  const [enhancements, setEnhancements] = useState({
    brightness: 50,
    contrast: 50,
    saturation: 50,
    sepia: 0,
    invert: 0
  });

 // Function to handle receiving enhancement values
 const handleEnhancementValues = useCallback((data) => {
  setEnhancements(data);
}, []);

useEffect(() => {
  socketio.registerEnhancementValuesReceiver(handleEnhancementValues);
  return () => {
    socketio.unregisterEnhancementValuesReceiver(handleEnhancementValues);
  };
}, [handleEnhancementValues]);

const handleEnhancementChange = (type, value) => {
  setEnhancements(prev => ({ ...prev, [type]: value }));
};

const handleFinalEnhancementValue = (type, value) => {
  socketio.sendImageEnhancementValues({ [type]: value });
};

const handleRestoreOptionChange = () => {
  const imageId = selectedImage
    ? selectedImage._id
    : currentImage
    ? currentImage._id
    : otherImages.length > 0
    ? otherImages[0]._id
    : null;

  if (imageId && restoreReason.trim()) {
    rejectService
      .unreject(projectId, [imageId], restoreReason)
      .then(() => {
        handleNextImage(false);
        setImageRejected(false); // Reset rejected state
        setShowRestoreInput(false); // Hide the input field
        setRestoreReason(""); // Clear the input field
        console.log("Image restoration successful");
      })
      .catch((error) => {
        console.error("Failed to restore image:", error);
      });
  } else {
    console.log("No images to restore or reason missing");
  }
};

  useEffect(() => {
    setLocalDiagnosisOptions(diagnosisOptions);
    setLocalPathologyOptions(pathologyOptions);
    setLocalTagOptions(tagOptions);
  }, [diagnosisOptions, pathologyOptions, tagOptions]);

// Polling and fetching options continuously
useEffect(() => {
  const fetchOptions = async () => {
    await dispatch(getSelectorOptionsThunk(projectId));
    // Update prevProjectId only after successfully fetching new options
    setPrevProjectId(projectId);
  };

  fetchOptions();

  const intervalId = setInterval(fetchOptions, 30000); // Continuous polling every 30 seconds

  return () => clearInterval(intervalId);
}, [projectId, dispatch]);

  // Logic for checking changes and setting the prompt
  useEffect(() => {
    const hasChanges = 
      JSON.stringify(diagnosisOptions) !== JSON.stringify(localDiagnosisOptions) ||
      JSON.stringify(pathologyOptions) !== JSON.stringify(localPathologyOptions) ||
      JSON.stringify(tagOptions) !== JSON.stringify(localTagOptions);
  
    if (prevProjectId === projectId){
      if (hasChanges) {
        setShowConfigPrompt(true);
      }
    }
  }, [diagnosisOptions, pathologyOptions, tagOptions, localDiagnosisOptions, localPathologyOptions, localTagOptions, prevProjectId, projectId]);


  const handlePromptAccept = () => {
    // Update local options with redux options
    setLocalPathologyOptions(pathologyOptions);
    setLocalDiagnosisOptions(diagnosisOptions);
    setLocalTagOptions(tagOptions);
    setShowConfigPrompt(false);
  };

  useEffect(() => {
    if (nextImage) {
      var combinedImages = []
      if (nextImage?.image && nextImage?.image?.otherImages && nextImage?.image?.otherImages?.length > 0) {
        combinedImages = [nextImage.image, ...nextImage.image.otherImages];
      } else {
        combinedImages = [nextImage.image]
      }
      setOtherImages(combinedImages);
    }
  }, [nextImage]);

  const handlePathologyOptionChange = (option) => {

    const _rules = project.rules
    const _new = option.label
    const current = selectedPathologies.length > 0 ? selectedPathologies.map(i=>i.text): []
    let ruleBreak = false
    // _rules.includes(_new) && current.some(value => _rules.includes(value))
    _rules.forEach(r=> {
      if (r.targets.includes(_new) && current.some(value => r.targets.includes(value)))
      {
        ruleBreak = true
      }
    })


    console.log('ruoe', _rules)
    console.log('ruoe', option)
    console.log('ruoe', selectedPathologies)
    if (option && !ruleBreak) {
      setSelectedPathologyOption(option);
      props.getPathologyColor(option);
      props.getPathologyLabel(option);
      props.onChange(option);
    }
    // console.log("option", option);
  };

  const handleDiagnosisOptionChange = (option) => {
    if (option.value !== "" && option.label !== "Select diagnosis") {
      setSelectedDiagnosisOption((prevState) => {
        // Ensure you're not directly mutating the state
        return { ...prevState, value: option.value, label: option.label };
      });
  
      // Propagate changes to parent or perform other actions
      props.getDiagnosisOptions(option);
    }
  };  

useEffect(() => {
  if (
    selectedDiagnosisOption.label &&
    selectedDiagnosisOption.label !== "Select diagnosis" &&
    selectedDiagnosisOption.label !== "Other" &&
    !selectedDiagnoses.includes(selectedDiagnosisOption.label)
  ) {
    const newDiagnoses = [
      ...selectedDiagnoses,
      selectedDiagnosisOption.label,
    ];

    if (newDiagnoses[0] !== "Select diagnosis") {
      dispatch(updateDiagnoses(newDiagnoses));
    }
  }

  if (
    isOtherDiagnosisSubmitted &&
    otherDiagnosisOption.label &&
    !selectedDiagnoses.includes(otherDiagnosisOption.label)
  ) {
    const newDiagnoses = [...selectedDiagnoses, otherDiagnosisOption.label];
    dispatch(updateDiagnoses(newDiagnoses));
    setIsOtherDiagnosisSubmitted(false); // Reset the flag after adding the diagnosis
  }
}, [
  selectedDiagnosisOption,
  otherDiagnosisOption,
  isOtherDiagnosisSubmitted,
]);


  useEffect(() => {
    setSelectedPathologyOption({value:"", label: "Select pathology"})
    // console.log("resetTrigger", props.resetTrigger)
  }, [props.resetTrigger])

  const token = localStorage.getItem("token");

  useEffect(() => {
    const checkImageCount = async () => {
      const response = await dispatch(getImageCount(token, projectId));
      const imageCount = response.data.count;
      // console.log('image Count2:', imageCount)
      if (imageCount === 0) {
        setShowZeroCountModal(true);
      } else {
        setShowZeroCountModal(false);
      }
    };
  
    checkImageCount();
  }, [dispatch, token, projectId, selectedImage]);

  const handleCheckboxChange = (event) => {
    if (event.target.checked) {
      dispatch(addNewTags([event.target.name]));
    } else {
      dispatch(removeTag(event.target.name));
    }
  };

  const closeSlider = () => {
    // Check if there are unsaved changes and the current image is selected
    if (selectedImage?._id === currentImage?.image._id &&
        (selectedPathologies?.length > 0 || selectedDiagnoses?.length > 0 || tags?.length > 0)) {
      // console.log('Showing prompt due to unsaved changes');
      setShowPrompt(true); // Show a prompt if there are unsaved changes
    } else {
      // console.log('No unsaved changes, or not the current image, toggling slider and lock if open');
      if (locked) { // Only toggle the lock if the slider is currently open
        dispatch(handleSliderlock());
      }
      dispatch(handleSliderToggles()); // Close the slider
    }
  };

  const handleLockToggle = () => {
      dispatch(handleSliderlock()); 
  };

  const handleProceed = () => {
    setShowPrompt(false); // Hide prompt
    clearDiagnosesAndPathologies(); // Clear data
    props.setIsEditable(false); // Set editable state to false
    props.handleEditId(null); // Clear editable ID
    setEditablePathologyIndex(null); // Clear editable pathology index
  
    // Close the slider and ensure it is unlocked if it was previously locked
    if(locked) {
      dispatch(handleSliderlock());// Ensure slider is unlocked when proceeding
    }
    dispatch(handleSliderToggles()) // Close the slider
  };
  
  const handleEdit = (id) => {
    if (editablePathologyIndex !== null) {
        setEditablePathologyIndex(null); 
        props.handleEditId(null);
        props.setIsEditable(false);
        return; 
    }
    setEditablePathologyIndex(id);
    props.handleEditId(id);
    props.setIsEditable(true);
  };

useEffect(() => {
  // console.log('selectedImage', selectedImage)
}, [selectedImage])

  const handleDelete = async (id) => {
    const image = selectedImage;
    setIsRemoving(true);
  
    if (image?.pathologies && image.pathologies?.pathologies?.length > 0) {
      const diagnosisData = {
        pathology: id,
        image: image._id,
        diagnosis: image.diagnosis,
        diagnoses: image.diagnoses,
        tags: image.tags,
        pathologies: image.pathologies.pathologies,
      };
  
      // Filter out both freehand and rectangle pathologies
      const updatedImagePathologies = {
        pathologies: image.pathologies?.pathologies.filter(
          (pathology) => pathology.coords.id !== id
        ),
      };
  
      const imageUpdate = {
        ...image,
        pathologies: updatedImagePathologies,
      };
  
      const updateDiagnosis = {
        pathology: id,
        image: selectedImage ? selectedImage._id : currentImage.image._id,
        diagnosis: selectedDiagnosisOption.value,
        diagnoses: selectedDiagnoses,
        tags: tags,
        pathologies: image.pathologies?.pathologies.filter(
          (pathology) => pathology.coords.id !== id
        ),
        projectId: projectId,
      };
  
      setLoadingStates((prevState) => ({
        ...prevState,
        [id]: true,
      }));
  
      await dispatch(createImageLabel(updateDiagnosis));
      setIsRemoving(false);
      dispatch(updateCurrentImage(imageUpdate));
      dispatch(removeLabelsImage(diagnosisData));
      dispatch(removePathology(id));
    } else {
      setIsRemoving(false);
      dispatch(removePathology(id));
    }
  };  
  
  useEffect(() => {
    if (selectedImage) {
    // console.log('cur image id', selectedImage._id)
    if (!otherImages.find(i => i._id === selectedImage._id )){
      // console.log('not in other')
      var _curImage = selectedImage
      _curImage = JSON.parse(JSON.stringify(_curImage))
      var _other = selectedImage.otherImages && selectedImage.otherImages.length > 0 ? selectedImage.otherImages : null
      if (_other) {
      _curImage.otherImages = null
      var _combined = [_curImage, ..._other]
      } else {
        var _combined = [_curImage]
      }
      // console.log('not in other, ', _combined)
      setOtherImages(_combined);
    } else {
      // console.log('in other')
    }
  }

    if (selectedImage?.pathologies?.pathologies?.length > 0) {
      const outputPathologies = selectedImage.pathologies.pathologies.map(
        (pathology) => {
          return {
            id: pathology.coords.id,
            x: pathology.coords.x,
            y: pathology.coords.y,
            width: pathology.coords.w,
            height: pathology.coords.h,
            text: pathology.name,
            color: getColorForValue(pathology.name),
          };
        }
      );
      dispatch(addNewPathologies(outputPathologies));
    }

    // Load diagnoses when an image is loaded
    if (selectedImage) {
      if (selectedImage?.pathologies?.diagnoses?.length > 0) {
        dispatch(updateDiagnoses(selectedImage.pathologies.diagnoses));
      } else {
        // dispatch(clearDiagnosesList());
      }
    }

    // Load tags when an image is loaded
    if (selectedImage?.pathologies?.tags?.length > 0) {
      dispatch(updateTags(selectedImage.pathologies.tags));
    } else {
      // dispatch(clearTagsList());
    }

    setImageRejected(isReject());
    setShowRestoreInput(false)
  }, [dispatch, selectedImage]);

  const handleNextImage = async ( reject = false) => {
    try {
      setLoadingNext(true);
      setError("");

      const pathologies = selectedPathologies.map((obj) => {
        const freehandRect = rectanglesFromFreehand.find(rect => rect.id === obj.id);
        return {
          coords: freehandRect 
            ? { id: freehandRect.id, x: freehandRect.x, y: freehandRect.y, w: freehandRect.width, h: freehandRect.height }
            : { id: obj.id, x: obj.x, y: obj.y, w: obj.width, h: obj.height },
          imgId: obj.imgId,
          name: obj.text,
          color: obj.fill,
          freehandpoints: obj.points || null,
        };
      });

      // Add updated bounding boxes from selectedRectanglesCoords
      selectedRectanglesCoords.forEach((coord) => {
        const matchingPathology = pathologies.find(
          (p) => p.coords.id === coord.id
        );
        if (matchingPathology) {
          matchingPathology.coords = {
            id: coord.id,
            x: coord.x,
            y: coord.y,
            w: coord.width,
            h: coord.height,
          };
        }
      });

      rectanglesFromFreehand.forEach((coord) => {
        const matchingPathology = pathologies.find(
          (p) => p.id === coord.id
        );
        
        if (matchingPathology) {
          matchingPathology.coords = {
            id: coord.id,
            x: coord.x,
            y: coord.y,
            w: coord.width,
            h: coord.height,
          };
        }
      });

      const diagnosisData = {
        image: selectedImage ? selectedImage._id : currentImage.image._id,
        diagnosis: selectedDiagnosisOption.value,
        diagnoses: selectedDiagnoses,
        tags: tags,
        pathologies: pathologies,
        comments: "",
        projectId: projectId,
        rejection: typeof reject === 'boolean' && reject === true ? reject : false
      };

      // console.log('break', diagnosisData, reject)

      // const _diagnosisData = JSON.parse(JSON.stringify(diagnosisData))
      // console.log('break parse', _diagnosisData)
      await dispatch(createImageLabel(diagnosisData));
      // console.log('break1')
      if (currentImage.image._id === selectedImage._id){
        await clearDiagnosesAndPathologies();
        await dispatch(getNextImage(token, projectId, projectAtLoginData));
      }

      // console.log('break5')
      setLoadingNext(false);

      // console.log('break6')
      dispatch(updateLabelsImage(diagnosisData));

      dispatch(getImageCount(token, projectId));
      const response = await dispatch(getImageCount(token, projectId));
      const imageCount = response.data.count;
      // console.log('image Count1:', imageCount)
      if (imageCount === 0) {
        setShowZeroCountModal(true);
      }
      
      if(!locked) {
        await dispatch(handleSliderToggles()) // Close the slider
      }
    } catch (error) {
      console.log('error', error)
      setError(error);
      setLoadingNext(false);
    }
  };

  const onSelectImage = (index) => {
    setCurrentDatasetIndex(index);
  };
  
  useEffect(() => {
    const checkImageCount = async () => {
      const response = await dispatch(getImageCount(token, projectId));
      const imageCount = response.data.count;

      // console.log('image Count5:', imageCount)
      if (imageCount === 0) {
        setShowZeroCountModal(true);
      } else {
        setShowZeroCountModal(false);
      }
    };

    checkImageCount();
  }, [dispatch, token, projectId]);

  const handleNormal = async () => {
    try {
      setLoading(true);
      setError("");
      const diagnosisData = {
        image: selectedImage ? selectedImage._id : currentImage._id,
        diagnosis: "Normal",
        diagnoses: ["Normal"],
        tags: [],
        pathologies: [],
        comments: "",
        projectId: projectId,
      };
      await dispatch(createImageLabel(diagnosisData));

      if (currentImage.image._id === selectedImage._id){
        await clearDiagnosesAndPathologies();
      await dispatch(getNextImage(token, projectId, projectAtLoginData));
      }
      setLoading(false);
      await dispatch(getImageCount(token, projectId));
      if(!locked) {
        await dispatch(handleSliderToggles()) // Close the slider
      }
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const  clearDiagnosesAndPathologies = async () => {
    setSelectedDiagnosisOption(() => {
      return { value: "", label: "Select diagnosis" };
    });
    setSelectedPathologyOption(() => {
      return { value: "", label: "Select pathology" };
    });
    await dispatch(clearDiagnosesList());
    await dispatch(clearTagsList());
    await dispatch(clearPathologiesList());
    await dispatch(clearFreehandPathologiesList());
    await dispatch(clearReactanglesFromFreehandList());
  };

const handleNextSet = async () => {

  try {
    setLoadingNext(true);
    setError("");

    // Filter images with pathologies before proceeding
    const imagesWithPathologies = otherImages.filter(image =>
      selectedPathologies.some(pathology => pathology.imgId === image._id)
    );

    // console.log('clicked save')
    // console.log('otherImages', otherImages) //has image, but not the selected image id on first save
    // console.log('imagesWithPathologies', imagesWithPathologies) //empty
    // console.log('selectedPathologies', selectedPathologies) // correct id
    // console.log('selectedImage', selectedImage)
    // console.log('currentImage', currentImage)

    for (let i = 0; i < imagesWithPathologies?.length; i++) {
      const _currentImage = imagesWithPathologies[i];
      const imagePathologies = selectedPathologies.filter(p => p.imgId === _currentImage._id);

      const diagnosisData = {
        image: selectedImage?._id ? selectedImage?._id : currentImage?._id,
        diagnosis: selectedDiagnosisOption.value,
        diagnoses: selectedDiagnoses,
        tags: tags,
        pathologies: imagePathologies.map(({id, x, y, width, height, text, color, points, planes, z, other, can3dSegment, window, mapped_stats, autoSegment, instanceId}) => {
          const freehandRect = rectanglesFromFreehand.find(rect => rect.id === id);
          return {
            coords: freehandRect ? { id: freehandRect.id, x: freehandRect.x, y: freehandRect.y, w: freehandRect.width, h: freehandRect.height } : { id, x, y, w: width, h: height },
            name: text,
            color: color,
            freehandpoints: points ? points: [],
            planes: planes ? planes: {},
            z: z ? z :0,
            other: other ? other : [],
            can3dSegment: can3dSegment? can3dSegment : false,
            window: window ? window : null,
            mapped_stats: mapped_stats? mapped_stats : {},
            autoSegment: autoSegment ? autoSegment : [],
            instanceId: instanceId ? instanceId : _currentImage.dicom.SOPInstanceUID ? _currentImage.dicom.SOPInstanceUID : null
          };
        }),
        comments: "",
        projectId: projectId,
      };

      // console.log('diagnosisData', diagnosisData)

      await dispatch(createImageLabel(diagnosisData));
      dispatch(updateLabelsImage(diagnosisData));
    }

    await fetchNextSetAndUpdateUI();
    setLoadingNext(false);
  } catch (error) {
    console.error("Error in handleNextSet:", error);
    setError(error.toString());
    setLoadingNext(false);
  }
};

const saveCurrentImageWithAnnotations = async (imageId) => {
  const imagePaths = selectedPathologies.filter(path => imageId === path.imgId);
  
  const diagnosisData = {
    image: imageId,
    diagnosis: selectedDiagnosisOption.value,
    diagnoses: selectedDiagnoses,
    tags: tags,
    pathologies: imagePaths.map(({id, x, y, width, height, text, points, planes, z}) => {
      const freehandRect = rectanglesFromFreehand.find(rect => rect.id === id);
      return {
        coords: freehandRect ? { id: freehandRect.id, x: freehandRect.x, y: freehandRect.y, w: freehandRect.width, h: freehandRect.height } : { id, x, y, w: width, h: height },
        name: text,
        freehandpoints: points,
        planes: planes,
        z: z
      };
    }),
    comments: "",
    projectId: projectId,
  };
  
  // console.log('diagnosisData', diagnosisData);
  // console.log('imageId', imageId);
  // console.log("saveCurrentImageWithAnnotations");

  await dispatch(createImageLabel(diagnosisData));
};

const markImageAsNormal = async (imageId) => {
  try {
    setLoadingNext(true);
    setError("");
    const diagnosisData = {
      image: imageId,
      diagnosis: "Normal",
      diagnoses: ["Normal"],
      tags: [],
      pathologies: [],
      comments: "",
      projectId: projectId,
    };
    await dispatch(createImageLabel(diagnosisData));
  } catch (error) {
    setError(error);
    setLoadingNext(false);
  }
    // console.log(`Marking image ${imageId} as normal...`);
};

const fetchNextSetAndUpdateUI = async () => {
    // Reset states for the next dataset
    setCurrentDatasetIndex(0);
    setImageSetExists(false);
    setOtherImages([]);

    // Fetch the next set of images
      // console.log('image check', currentImage)
      // console.log('image check', selectedImage)
      // console.log('image check', previousImages)
      // console.log('image check', projectId)
      const islocalNext = previousImages.filter(p=> p.pID === projectId)
      // console.log(islocalNext)
      const isNext = islocalNext.findIndex(i=> i.image._id === selectedImage._id)
      // console.log('image check', islocalNext)
      // console.log('image check', isNext)
      if (currentImage.image._id === selectedImage._id || isNext===0){
        await clearDiagnosesAndPathologies();
        await dispatch(getNextImage(token, projectId, projectAtLoginData));
      }
    setLoadingNext(false);

    const response = await dispatch(getImageCount(token, projectId));
    const imageCount = response.data.count;
    // console.log('image Count4:', imageCount)
    if (imageCount === 0) {
      setShowZeroCountModal(true);
    }

    // console.log('Locked', locked)
    if (!locked && !openWindow) {
      await dispatch(handleSliderToggles()) // Close the slider
    }
};

const handleNormalSet = async () => {
  setLoading(true);
  try {
    if (otherImages.length > 0) {
      const image = otherImages[0]; // Select the first image or any single image from the array
      const imageId = image._id; 

      // console.log('imageId', imageId);
      
      const diagnosisData = {
        image: imageId, 
        diagnosis: "Normal",
        diagnoses: ["Normal"],
        tags: [],
        pathologies: [],
        comments: "",
        projectId: projectId,
      };

      await dispatch(createImageLabel(diagnosisData));
      // console.log("diagnosisData", diagnosisData);

      // console.log('image check', currentImage)
      // console.log('image check', selectedImage)
      if (currentImage.image._id === selectedImage._id){
        await clearDiagnosesAndPathologies();
        await dispatch(getNextImage(token, projectId, projectAtLoginData));
      }

      setLoadingNext(false);
      dispatch(updateLabelsImage(diagnosisData));

      dispatch(getImageCount(token, projectId));
      const imageCount = dispatch(getImageCount(token, projectId));

      // console.log('image Count3:', imageCount)

      if (imageCount === 0) {
        setShowZeroCountModal(true);
      }
      
      if (!locked && !openWindow) {
        await dispatch(handleSliderToggles()) // Close the slider
      }
    }
  } catch (error) {
    console.error("Error marking set as normal:", error);
    setError(error.toString());
  } finally {
    setLoading(false);
  }
};

  const imagesSet = selectedImage?.image_set

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        if (imagesSet && imagesSet.name) {
          setImageSetExists(true);
          setImageSet(imagesSet);
        } else {
          setImageSetExists(false);
        }
  
      } catch (error) {
        setError(error);
      }
    };
  
    fetchInitialData();
    // console.log('imageSetExists',imagesSet?.name)
  }, [dispatch, token, projectId, projectAtLoginData, imagesSet]);

  useEffect(() => {
    // console.log('launched', startPolling)
  }, [startPolling])

  const handleAiLabels = async () => {
    // console.log('handleAiLabels');
  
    if (isSegmentationLoaded) {
      // console.log('Segmentation already loaded');
      return;
    }
  
    try {
      const response = await aiLabelService.getAiLabels(projectId, imageId);
      // console.log('AI Labels:', response);
  
      const aiPathologies = response?.flatMap(image => 
        image?.pathologies?.map(pathology => ({
          id: pathology?.id,
          coords: {
            x: pathology?.coords?.x,
            y: pathology?.coords?.y,
            w: pathology?.coords?.w,
            h: pathology?.coords?.y,
          },
          text: pathology?.name,
          color: pathology?.color,
          freehandpoints: pathology?.points,
          planes: pathology?.planes,
          z: pathology?.z,
          other: pathology?.other,
          can3dSegment: pathology?.can3dSegment,
          window: image?.window,
          mapped_stats: pathology?.mapped_stats,
          autoSegment: pathology?.autoSegment,
          instanceId: pathology?.instanceId
        }))
      );

      // console.log('aiPathologies', aiPathologies)
      dispatch(updateFreehandDrawingsList(aiPathologies))  
      socketio.aiBroadcastValues(response);
      setIsSegmentationLoaded(true);
    } catch (error) {
      console.error('Error fetching AI labels:', error);
    }
  };

  return (
    <>
      {showZeroCountModal && (
        <div className="zero-count-prompt">
          <p>
            There are no more images to annotate in this project.
          </p>
          <p>
            Please click proceed and select a new project.
          </p>
          <div className="zero-count-prompt-button">
            <button onClick={() => setShowZeroCountModal(false)}>Proceed</button>
          </div>
        </div>
      )}
      {open && (
        <div
          className={`${!openWindow ? "col-3 slide-out-div" : ""} ${
            open ? "active" : ""
          }`}
        >
          <div className="slider-body">
            <div>
            <FontAwesomeIcon 
              icon={faArrowRight} 
              onClick={openWindow ? undefined : closeSlider}
              color={openWindow ? "#cccccc" : "#ffc848"}
              style={{
                float: "right",
                margin: "22px 20px 0 0",
                fontSize: "20px",
                cursor: openWindow ? "default" : "pointer"
              }} 
              title="Close"
            />
            </div>
            <div className="toggle-container">
            {
              project.version === 1 && (
                <div className="toggle-switch" title={props.isFreeHand ? `Drawing Mode: Freehand`: 'Drawing Mode: Box'}>
                  <input
                    type="checkbox"
                    className="checkbox"
                    id="drawing-mode-toggle"
                    checked={props.isFreeHand}
                    // onChange={handleChange}
                    disabled
                  />
                  <label className="label" htmlFor="drawing-mode-toggle" style={{ borderColor: props.isFreeHand ? '#0E2942' : '#0E2942' }}>
                    <span className="inner" />
                    <span className="switch">
                      <FontAwesomeIcon icon={props.isFreeHand ? faPencil: faSquare} color={props.isFreeHand ? '#0e2942' : '#3981C8'} />
                    </span>
                  </label>
                </div>
              )
            }
            <div className="toggle-switch" title="Slide-Out Tools Lock">
              <input
                type="checkbox"
                className="checkbox"
                id="slide-out-lock-toggle"
                checked={locked}
                onChange={handleLockToggle}
              />
              <label className="label" htmlFor="slide-out-lock-toggle" style={{ borderColor: locked ? '#0E2942' : '#0E2942' }}>
                <span className="inner" />
                <span className="switch">
                  <FontAwesomeIcon icon={locked ? faLock : faLockOpen} color={locked ? 'red' : 'green'} />
                </span>
              </label>
            </div>

          </div>
            {showPrompt && selectedImage?._id === currentImage?.image?._id && (
              <div className="prompt">
                <p>
                  The current unsaved annotations will be removed. Would you like to proceed?
                </p>
                <div className="prompts">
                  <button onClick={() => setShowPrompt(false)}>Cancel</button>
                  <button onClick={handleProceed}>Proceed</button>
                </div>
              </div>
            )}

            {startPolling && (
              <ViewerPolling 
                startPolling={startPolling}
                clickTimestamp={clickTimestamp}
              />
            )}

            {imageSetExists && project.version === 1 &&
            <>
              <FilmStrip
                currentDatasetIndex={currentDatasetIndex}
                onSelectImage={onSelectImage}
                onLaunchViewer={handleLaunchViewer}
              />
            </>
            }

            <div className="dropdown-container">
              {open && selectedPathologies && (
                <>
                  <h6 style={{ color: "#fff", margin: "4px 10px 6px 10px" }}>
                    Pathologies
                    <span
                    className="badge ml-2 bg-yellow color-black font-weight-400 badge-secondary badge-pill"
                    title="Number of pathologies"
                  >
                    {pathCount ? pathCount : 0}
                  </span>
                  </h6>
                  <div className="pathology-flex-slider">
                    <div
                      className="dropdown-first-select"
                      title={
                        editablePathologyIndex
                          ? "Please deselect edit first by clicking the tick"
                          : ""
                      }
                    >

                    {project.version === 1 && 
                      <DropdownSelect
                        disabled={
                          editablePathologyIndex !== null ||
                          props.isEditable === true
                        }
                        selectedOption={selectedPathologyOption}
                        onChange={handlePathologyOptionChange}
                        options={[
                          ...(editablePathologyIndex
                            ? [{ value: "", label: "Select pathologies" }]
                            : []),
                            ...localPathologyOptions,
                        ]}
                        title={
                          editablePathologyIndex
                            ? "Please deselect edit first by clicking the tick"
                            : ""
                        }
                      />
                    }

                    </div>

                    {project.version === 1 ? (
                      <div>
                        {selectedPathologyOption.label === "Other" && (
                          <div
                            style={{
                              width: "calc(100%-12px)",
                              margin: "10px 12px 16px 9px",
                              display: "flex",
                              height: "36px",
                            }}
                          >
                            <input
                              id="otherDiagnosisInput"
                              name="otherPathologyInput"
                              type="text"
                              placeholder="Other:"
                              list="otherOptionList"
                              style={{ height: "100%" }}
                              onChange={(e) =>
                                setSelectedPathologyOtherOption({
                                  value: e.target.value,
                                  label: e.target.value,
                                })
                              }
                            ></input>
                            <datalist id="otherOptionList">
                              {pathologyOtherOptions.map((d, i) => {
                                return <option key={i} value={d.value}></option>;
                              })}
                            </datalist>
                            <FontAwesomeIcon
                              className="btn btn-primary slider-button"
                              style={{ color: "#fff", padding: "9.5px 9.5" }}
                              icon={faArrowRight}
                              onClick={() => {
                                if (selectedPathologyOtherOption.value !== "") {
                                  handlePathologyOptionChange(
                                    selectedPathologyOtherOption
                                  );
                                }
                              }}
                            />
                          </div>
                        )}
                        <div
                          style={{
                            background: "#081624",
                            width: "calc(100%-12px)",
                            height: "100%",
                            marginTop: "12px",
                            marginBottom: "24px",
                            borderRadius: 7,
                            display: "flex",
                            flexWrap: "wrap",
                          }}
                        >
                          {selectedPathologies.map((item, i) => {
                            if (item.imgId === selectedImage._id) {
                            const indices = selectedPathologies
                              .map((pathology) => pathology.text)
                              .reduce((acc, pathology, index) => {
                                if (pathology === item.text) {
                                  acc.push(index + 1);
                                }
                                return acc;
                              }, []);
                            const index = indices.indexOf(i + 1);
                            return (
                              <div key={i} style={{ display: "flex" }}>
                                <p style={{ color: "#fff", margin: "20px 14px" }}>
                                  {`${index + 1} ${item.text}`}
                                </p>
                            
                                {true && (
                                  <FontAwesomeIcon
                                    icon={
                                      editablePathologyIndex === item.id
                                        ? faCheck
                                        : faEdit
                                    }
                                    style={{
                                      color:
                                        editablePathologyIndex === item.id
                                          ? "green"
                                          : "yellow",
                                      marginTop: "23px",
                                      marginRight: "3px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      handleEdit(item.id);
                                      if (props.isEditable) {
                                        props.setIsEditable(false);
                                      }
                                    }}
                                  >
                                    Edit
                                  </FontAwesomeIcon>
                                )}

                                { editablePathologyIndex !== item.id &&
                                  <FontAwesomeIcon
                                  key={item.id}
                                  spin={loadingStates[item.id]}
                                  disabled={loadingStates[item.id] || isRemoving}
                                  icon={
                                    loadingStates[item.id] ? faSpinner : faTrash
                                  }
                                  style={{
                                    color: isRemoving ? "gray" : "red",
                                    marginTop: "23px",
                                    marginLeft: "3px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    cursor: isRemoving ? "not-allowed" : "pointer",
                                  }}
                                  onClick={() => {
                                    handleDelete(item.id).finally(() => {
                                      setLoadingStates((prevState) => ({
                                        ...prevState,
                                        [i]: false,
                                      }));
                                    });
                                  }}
                                ></FontAwesomeIcon>
                                }
                              </div>
                            );
                          }
                          })}
                        </div>
                        
                        {showConfigPrompt && (
                          <div style={{ display: 'flex', alignItems: 'center', color: '#ffc848', margin: '10px' }}>
                            <FontAwesomeIcon icon={faExclamationCircle} style={{ color: 'red', marginRight: '10px', fontSize: '22px' }} />
                            <p style={{ margin: '0', marginRight: '5px' }}>Project configuration options have changed. Update now?</p>
                            <button style={{ color: '#ffc848', margin: '0 5px 0 5px', border: '2px solid #ffc848', backgroundColor: 'transparent' }} onClick={handlePromptAccept}>Yes</button>
                          </div>
                        )}
                        {editablePathologyIndex ? 
                        <div style={{ display: 'flex', alignItems: 'center', color: '#ffc848', margin: '16px' }}>
                          <FontAwesomeIcon icon={faExclamationCircle} style={{ color: 'red', fontSize: '22px' }} />
                          <p style={{ margin: '5px 0 5px 12px', color: '#ffc848'}}>Please de-select edit first by clicking the tick</p>
                        </div>
                         : null}
                          </div>
                    ) : 
                      <Pathologies/>
                    }
                  </div>
                </>
              )}

              {open && (
                <div 
                >
                  <h6 style={{ color: "#fff", margin: "4px 0 6px 10px" }}>
                    Diagnosis
                  </h6>
                  <div className="diagnosis-flex-slider">
                    <div className="dropdown-select">
                      <DropdownSelect
                        selectedOption={displayedDiagnosisOption}
                        onChange={handleDiagnosisOptionChange}
                        options={localDiagnosisOptions}
                        placeholder="Select diagnosis"
                      />
                    </div>
                  </div>

                  {selectedDiagnosisOption.label === "Other" && (
                    <div
                      style={{
                        width: "calc(100%-12px)",
                        margin: "10px 12px 16px 9px",
                        display: "flex",
                        height: "36px",
                      }}
                    >
                      <input
                        id="otherDiagnosisInput"
                        name="otherDiagnosisInput"
                        type="text"
                        placeholder="Other:"
                        style={{ height: "100%" }}
                        onChange={(e) =>
                          setOtherDiagnosisOption({
                            value: e.target.value,
                            label: e.target.value,
                          })
                        }
                      />
                      <FontAwesomeIcon
                        className="btn btn-primary slider-button"
                        style={{ color: "#fff", padding: "9.5px 9.5" }}
                        icon={faArrowRight}
                        onClick={() => {
                          if (otherDiagnosisOption.value !== "") {
                            handleDiagnosisOptionChange(otherDiagnosisOption);
                          }
                        }}
                      />
                    </div>
                  )}
                  {error && (
                    <div className="ml-2 alert alert-danger">
                      Something went wrong, Please try again later.
                    </div>
                  )}

                  <div
                    style={{
                      background: "#081624",
                      width: "calc(100%-12px)",
                      height: "100%",
                      marginLeft: "9px",
                      marginRight: "12px",
                      borderRadius: 7,
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                  >
                    {selectedDiagnosisOption?.value !== "Other" && (
                      <DiagnosisChip
                        selectedOption={selectedDiagnosisOption?.value}
                        handleDiagnosisOptionChange={
                          handleDiagnosisOptionChange
                        }
                        selectedDiagnoses={selectedDiagnoses}
                      />
                    )}
                  </div>
                </div>
              )}

              {open && localTagOptions.length > 0 &&(
                <div>
                  <h6 style={{ color: "white", padding: "8px" }}>Tags</h6>
                  <div
                    style={{
                      background: "#081624",
                      width: "calc(100%-12px)",
                      height: "100%",
                      marginLeft: "9px",
                      marginTop: "12px",
                      marginRight: "12px",
                      borderRadius: 7,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        marginRight: "20px",
                        marginTop: "10px",
                        marginLeft: "10px"
                      }}
                    >
                      {localTagOptions?.map((tag, index) => (
                        <div 
                          key={index} 
                          className="tag-container">
                          <input
                            type="checkbox"
                            id={`tag-${index}`} 
                            name={tag}
                            checked={tags.includes(tag)} 
                            onChange={handleCheckboxChange}
                            className="custom-checkbox"
                          />
                          <label 
                            htmlFor={`tag-${index}`} 
                            className="tag-label"
                          >
                            {tag}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

              )}



              {!imageSetExists && project.version !== 2 ? (
                  <div className="controls-flex"  style={{ marginInline: 'auto', width: 'fit-content', marginTop: '5px' }}>
                    <Button
                      disabled={
                        loadingNext ||
                        selectedPathologies?.length === 0 ||
                        editablePathologyIndex != null ||
                        selectedDiagnoses?.length === 0
                      }
                      className="btn btn-primary slider-button"
                      title={
                        selectedPathologies?.length === 0
                          ? "Please select a pathology and annotate the image"
                          : editablePathologyIndex != null
                          ? "Please deselect edit first by clicking the tick"
                          : ""
                      }
                      onClick={handleNextImage}
                    >
                      {loadingNext ? (
                        <>
                          <Spinner animation="border" variant="light" size="sm" className="mr-2" />
                          <small>Loading...</small>
                        </>
                      ) : selectedImage?.pathologies ? (
                        <small>Save</small>
                      ) : (
                        <small>Next</small>
                      )}
                    </Button>
                    <Button
                      className="btn btn-success slider-button"
                      onClick={handleNormal}
                      disabled={loading || selectedPathologies?.length > 0}
                    >
                      {loading ? (
                        <>
                          <Spinner animation="border" variant="light" size="sm" className="mr-2" />
                          <small>Loading...</small>
                        </>
                      ) : (
                        <small>Normal</small>
                      )}
                    </Button>
                  <div className="dropdown-select reject-dropdown">
                    {imageRejected ? (
                      // Restore Input with Right Arrow
                      !showRestoreInput && (
                        <button
                          onClick={() => setShowRestoreInput(true)} // Show the input
                          className="mb-6 px-2 btn btn-success"
                        >
                          Restore
                        </button>
                      )
                    ) : (
                      // Reject Dropdown using DropdownSelect
                      <DropdownSelect
                        selectedOption={selectedRejectOption || { value: "", label: "Reject" }}
                        onChange={handleRejectOptionChange}
                        options={rejectOptions || []}
                        disabled={loading || selectedPathologies?.length > 0}
                        placeholder="Reject"
                      />
                    )}
                  </div>

                  </div>
                  ) : (
                    <div className="controls-flex-imageset" style={{ padding: '10px 0', marginInline: 'auto', width: 'fit-content', marginTop: '5px' }}>
                      <Button 
                        className="next-set" 
                        onClick={handleNextSet}
                        style={{ backgroundColor: 'green', borderColor: 'green' }}
                        disabled={selectedPathologies?.length <= 0 || selectedDiagnoses?.length <= 0}
                      >
                        {loadingNext ? (
                          <>
                            <Spinner animation="border" variant="light" size="sm" className="mr-2" />
                            <small>Processing...</small>
                          </>
                        ) : (
                          <small>Next Study</small>
                        )}
                      </Button>
                      <Button 
                        onClick={handleNormalSet} 
                        disabled={selectedPathologies?.length > 0 || selectedDiagnoses?.length > 0}
                      >
                        {loading ? (
                          <>
                            <Spinner animation="border" variant="light" size="sm" className="mr-2" />
                            <small>Processing...</small>
                          </>
                        ) : (
                          <small>Normal</small>
                        )}
                      </Button>
                      <div className="dropdown-select reject-dropdown">
                    {imageRejected ? (
                      // Restore Input with Right Arrow
                      !showRestoreInput && (
                        <button
                          onClick={() => setShowRestoreInput(true)} // Show the input
                          className="mb-6 px-2 btn btn-success"
                        >
                          Restore
                        </button>
                      )
                    ) : (
                      // Reject Dropdown using DropdownSelect
                      <DropdownSelect
                        selectedOption={selectedRejectOption || { value: "", label: "Reject" }}
                        onChange={handleRejectOptionChange}
                        options={rejectOptions || []}
                        disabled={loading || selectedPathologies?.length > 0}
                        placeholder="Reject"
                      />
                    )}
                  </div>
                      {project.version === 2 && (
                      <div className='ai-button'>
                      <Button
                        onClick={handleAiLabels}
                        disabled={(pathologyOptions.ai_enabled === false) || isSegmentationLoaded}

                      >
                        AI
                        <FontAwesomeIcon icon={faWandMagicSparkles} style={{ marginLeft: '8px' }} />
                      </Button>
                      </div>)}
                    </div>
                  )}{
                    <div>
                      { imageRejected && showRestoreInput && (
                        <div                       
                        style={{
                          width: "calc(100%-12px)",
                          margin: "20px 12px 16px 9px",
                          display: "flex",
                          height: "36px",
                        }}>
                          <input
                            type="text"
                            placeholder="Enter restore reason"
                            value={restoreReason}
                            onChange={(e) => setRestoreReason(e.target.value)}
                            className="form-control"
                            style={{ flex: 1 }}
                          />
                          <FontAwesomeIcon
                            icon={faArrowRight}
                            onClick={handleRestoreOptionChange}
                            className="btn btn-primary slider-button"
                            style={{ color: "#fff", padding: "9.5px 9.5" }}
                          />
                          <FontAwesomeIcon
                            icon={faTimes}
                            onClick={handleCancelRestore}
                            className="btn btn-danger slider-button"
                            style={{ color: "#fff", padding: "0 12px", fontSize: "1.2em", marginLeft: "10px" }}
                          />
                        </div>
                      )}
                      
                    </div>
                  }
                  {project.version === 1 && (
                <div>
                  <h6
                    className="text-white mb-2"
                    style={{ color: "white", padding: "10px" }}
                  >
                    Image Enhancement
                  </h6>
                  <div
                    style={{
                      background: "#081624",
                      width: "calc(100%-12px)",
                      height: "100%",
                      marginLeft: "9px",
                      marginTop: "12px",
                      marginRight: "12px",
                      borderRadius: 7,
                    }}
                  >
                    <div className="slidein enhancement bg-navy p-3 mt-2">
                      <div className="row align-items-center">
                        <div className="col-3 pt-2 slider-label">
                          Brightness
                        </div>
                        <div className="col-1 remove-icon">
                          <div className="input-group-prepend">
                            <div className="input-group-text bg-transparent border-0">
                              <FontAwesomeIcon
                                icon={faSun}
                                className="color-white"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-8">
                          <RCSlider
      min={0}
      max={100}
      defaultValue={enhancements.brightness}
      onChange={(value) => handleEnhancementChange('brightness', value)}
      onMouseUp={() => handleFinalEnhancementValue('brightness', enhancements.brightness)}
      onTouchEnd={() => handleFinalEnhancementValue('brightness', enhancements.brightness)}    />
                        </div>
                      </div>

                      <div className="row align-items-center">
                        <div className="col-3 pt-2 slider-label">Contrast</div>
                        <div className="col-1 remove-icon">
                          <div className="input-group-prepend">
                            <div className="input-group-text bg-transparent border-0">
                              <FontAwesomeIcon
                                icon={faSun}
                                className="color-white"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-8 reduced-slider">
                          <RCSlider
      min={0}
      max={100}
      defaultValue={enhancements.contrast}
      onChange={(value) => handleEnhancementChange('contrast', value)}
      onMouseUp={() => handleFinalEnhancementValue('contrast', enhancements.contrast)}
          onTouchEnd={() => handleFinalEnhancementValue('contrast', enhancements.contrast)}
    />
                        </div>
                      </div>

                      <div className="row align-items-center">
                        <div className="col-3 pt-2 slider-label">
                          Saturation
                        </div>
                        <div className="col-1 remove-icon">
                          <div className="input-group-prepend">
                            <div className="input-group-text bg-transparent border-0">
                              <FontAwesomeIcon
                                icon={faSun}
                                className="color-white"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-8">
                          <RCSlider
      min={0}
      max={100}
      defaultValue={enhancements.saturate}
      onChange={(value) => handleEnhancementChange('saturate', value)}
      onMouseUp={() => handleFinalEnhancementValue('saturate', enhancements.saturate)}
      onTouchEnd={() => handleFinalEnhancementValue('saturate', enhancements.saturate)}    />
                        </div>
                      </div>

                      <div className="row align-items-center">
                        <div className="col-3 pt-2 slider-label">Sepia</div>
                        <div className="col-1 remove-icon">
                          <div className="input-group-prepend">
                            <div className="input-group-text bg-transparent border-0">
                              <FontAwesomeIcon
                                icon={faSun}
                                className="color-white"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-8">
                          <RCSlider
      min={0}
      max={100}
      defaultValue={enhancements.sepia}
      onChange={(value) => handleEnhancementChange('sepia', value)}
      onMouseUp={() => handleFinalEnhancementValue('sepia', enhancements.sepia)}
      onTouchEnd={() => handleFinalEnhancementValue('sepia', enhancements.sepia)}    />
                        </div>
                      </div>

                      <div className="row align-items-center">
                        <div className="col-3 pt-2 slider-label">Invert</div>
                        <div className="col-1 remove-icon">
                          <div className="input-group-prepend">
                            <div className="input-group-text bg-transparent border-0">
                              <FontAwesomeIcon
                                icon={faSun}
                                className="color-white"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-8">
<RCSlider
      min={0}
      max={100}
      defaultValue={enhancements.invert}
      onChange={(value) => handleEnhancementChange('invert', value)}
      onMouseUp={() => handleFinalEnhancementValue('invert', enhancements.invert)}
      onTouchEnd={() => handleFinalEnhancementValue('invert', enhancements.invert)}    />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Slider;
