export function localhostRoute() {
  // return {
  //   label: "https://label.test.ratify.global",
  //   // label: "http://localhost:3000",
  //   viewer: "https://label.test.ratify.global/viewer",
  //   comms: "https://comms.test.ratify.global"
  // };
  return {
    label: "https://label.test.ratify.global",
    // label: "http://localhost:3000",
    viewer: "https://label.test.ratify.global/viewer",
    comms: "https://comms.test.ratify.global"
    // comms: "http://localhost:4090"
  };
}
